<template>
    <div class="report">
        <top-bar :reportDetailModel="reportDetailModel" :selected-status.sync="selectedStatus"
            @onClickDownload="onClickDownload" @selectInputChange="selectInputChange"
            @onClickChange="onClickChange"></top-bar>
        <chart-panel ref="chartPanel" :reportDetailModel="reportDetailModel"
            :selected-status="selectedStatus"></chart-panel>
        <select-report ref="selectReport" :hospitalId="hospitalId" @onSelectReport="onSelectReport"></select-report>
    </div>
</template>

<script>
import TopBar from '@/components/custom-report/shengfubao/top-bar.vue';
import ChartPanel from '@/components/custom-report/shengfubao/chart-panel.vue';
import SelectReport from '@/components/custom-report/shengfubao/select-report.vue';
import html2canvas from "html2canvas";
export default {
    components: {
        TopBar,
        ChartPanel,
        SelectReport
    },

    data() {
        return {
            selectedStatus: [true, true, true, true, true, true, true],
            reportDetailModel: null,
            hospitalId: 351, //省妇保hospitalId： 351
        }
    },
    mounted() {

    },
    watch: {
        reportDetailModel(newVal) {
            this.selectedStatus = [true, true, true, true, true, true, true]
        }
    },

    methods: {
        onClickDownload() {
            if (!this.reportDetailModel) {
                this.$toast.showRed('请先选择报告')
                return
            }
            var shareContent = document.getElementById("contentBox");
            const clientWidth = shareContent.offsetWidth
            const clientHeight = shareContent.offsetHeight
            const kh = [clientWidth, clientHeight]
            html2canvas(shareContent, {
                useCORS: true,
                logging: true
            }).then(canvas => {
                const dataURL = canvas.toDataURL('image/jpeg')
                this.download(dataURL, kh)
            })
        },
        //数据选择
        selectInputChange() {
        },
        //点击报告变更
        onClickChange() {
            this.$refs.selectReport?.show()
        },

        //选择报告成功
        onSelectReport(report) {
            this.reportDetailModel = report
        },
        getUrlBase64(url, kh) {
            return new Promise(resolve => {
                let canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                const img = new Image()
                img.crossOrigin = 'Anonymous' // 允许跨域
                img.src = url
                img.onload = () => {
                    // eslint-disable-next-line prefer-destructuring
                    canvas.height = kh[1]
                    canvas.width = kh[0]
                    ctx.drawImage(img, 0, 0, kh[0], kh[1])
                    const dataURL = canvas.toDataURL('image/jpeg')
                    canvas = null
                    resolve(dataURL)
                }
            })
        },
        download(imgUrl, kh) {
            this.getUrlBase64(imgUrl, kh).then(base64 => {
                const link = document.createElement('a')
                link.href = base64
                link.download = `${'睡眠报告'+'-'+placeholderText(this.reportDetailModel?.initialPatientName) + '-' + this.reportDetailModel.createDate}.png`
                link.click()
            })
        },

    },
}
</script>
<style scoped lang='scss'>
.report {
    width: 100%;
    min-width: 1200px;
    height: 100vh;
    background-color: white;
    padding: 20px 0px;
    box-sizing: border-box;
}</style>