<template>
    <div class="top-bar">
        <p class="top-bar-info">
            选择报告：{{title}}
        </p>
        <button class="top-bar-change" @click.prevent="onClickChange">更换</button>
        <p class="top-bar-select-data">
            选择数据：
        <ul class="top-bar-select-data-ul">
            <li class="top-bar-select-data-ul-li" v-for="(item, index) in selectDataList" :key="item.id">
                <input type="checkbox" :id="item.id" :name="item.id" v-model="item.checked" @change="inputChange">
                <label :for="item.id">{{item.title}}</label>
            </li>
        </ul>
        </p>
        <button class="top-bar-download" @click.prevent="onClickDownload">下载为图片</button>
    </div>
</template>

<script>
import { DateTool } from "@js/date-tool.js";
export default {
    props: {
        selectedStatus: Array,
        reportDetailModel: Object,
    },
    data() {
        return {
            selectDataList: [
                {
                    title: '睡眠分期',
                    id: 'report-data-1',
                    checked: true
                },
                {
                    title: '脉率',
                    id: 'report-data-2',
                    checked: true
                },
                {
                    title: '血氧',
                    id: 'report-data-3',
                    checked: true
                },
                {
                    title: '呼吸',
                    id: 'report-data-4',
                    checked: true
                },
                {
                    title: '呼吸事件',
                    id: 'report-data-5',
                    checked: true
                },
                {
                    title: '体动占比',
                    id: 'report-data-6',
                    checked: true
                },
                {
                    title: '体动幅度',
                    id: 'report-data-7',
                    checked: true
                },
            ],
        };
    },
    watch: {
        selectedStatus: {
            handler(newVal) {
                newVal.forEach((el, index) => {
                    this.$set(this.selectDataList[index], 'checked', el)
                });
            },
            immediate: true,
        }
    },

    computed: {
        title() {
            if (!this.reportDetailModel) {
                return '- -'
            }
            return placeholderText(this.reportDetailModel?.initialPatientName) + ' ' + this.reportDetailModel.createDate
        }
    },

    created() {
    },
    mounted() {
    },
    methods: {
        onClickChange() {
            this.$emit('onClickChange');
        },
        onClickDownload() {
            this.$emit('onClickDownload');
        },
        inputChange() {
            this.$emit('update:selectedStatus', this.selectDataList.map(el => el.checked));
            this.$emit('selectInputChange');
        }
    }
}
</script>
<style scoped lang='scss'>
@mixin button-style {
    color: #325FAC;
    font-size: 18px;
    font-family: inherit;
    padding: 0;
}

.top-bar {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000000;
    height: 25px;
    background-color: white;
    line-height: 25px;
    padding: 0 20px;

    &-info {}

    &-change {
        margin-left: 20px;
        @include button-style;
    }

    &-select-data {
        margin-left: 60px;
        display: flex;
        align-items: center;

        &-ul {
            display: flex;
            align-items: center;

            &-li {
                margin-left: 20px;
                // font-family: Arial, Helvetica, sans-serif;
                font-size: 14px;
                font-weight: normal;
                color: #262626;

                display: flex;
                align-items: center;

                input[type=checkbox] {
                    margin: 4px 10px 4px 0;
                    position: relative;

                }

                input[type=checkbox]::after {
                    position: absolute;
                    top: 0;
                    color: #000;
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    visibility: visible;
                    padding-left: 0px;
                    text-align: center;
                    content: ' ';
                    border-radius: 2px;
                }

                input[type=checkbox]:checked::after {
                    content: "✓";
                    color: #fff;
                    font-size: 10px;
                    line-height: 15px;
                    background-color: #325FAC;
                }

                &:first-of-type {
                    margin-left: 0;
                }
            }
        }
    }

    &-download {
        margin-left: auto;
        @include button-style;
    }
}
</style>