<template>
    <div id="contentBox" :style="{ height: boxHeight - dataZoomHeight - offsetTop + 50 + 'px' }">
        <div class="box" :style="{ height: boxHeight - dataZoomHeight - offsetTop + 'px' }"></div>
        <div class="leftTitleBox" :style="{
            height: boxHeight - dataZoomHeight - offsetTop + 'px',
            paddingTop: offsetTop + 'px',
        }">
            <div v-for="(item, k) in chartData" :key="k" class="itemTitle" :style="{
                height: `calc((100% - ${(baseSpace / chartData.length) *
                    2 *
                    (chartData.length - 1)
                    }px) / ${chartData.length})`,
            }">
                <div class="itemTitleContent">
                    {{ item.title }}
                </div>

            </div>
        </div>
        <div :id="chartId" ref="chartBox"></div>
    </div>
</template>

<script>
import { SleepChartTool } from "@c/report-detail/sleep-chart/sleep-chart-tool.js";
export default {
    props: {
        reportDetailModel: Object,
        selectedStatus: Array,
    },

    data() {
        return {
            chartId: 'sheng-fu-bao-chart',
            boxHeight: 0,
            dataZoomHeight: 100,
            offsetTop: 0, // 距离顶部多少像素
            baseSpace: 0, // 间隔基数
            chart: null,
            chartData: [],
        }
    },

    watch: {
        reportDetailModel(model) {
            this.setupReportModel(model)
        },

        selectedStatus(newVal) {
            if (!this.reportDetailModel) {
                return
            }
            this.setupReportModel(this.reportDetailModel)
        }
    },

    computed: {
    },

    mounted() {
        this.boxHeight = this.$refs.chartBox.offsetHeight;
        this.chart = this.$echarts.init(document.getElementById(this.chartId));
        // this.drawLine();
        window.addEventListener('resize', () => {
            this.chart?.resize()
        })
    },

    methods: {
        setupReportModel(model) {
            const pulseChartDataArray = model.pulseChartDataArray
            let valueArray = pulseChartDataArray.map((item) => {
                return item[1] ? item[1] : 0;
            });
            const yMax = Math.max(...valueArray);

            const breathEventChartDataArray = model.breathEventChartDataArray
            let eventValueArray = breathEventChartDataArray.map((item) => {
                return item[1] ? item[1] : 0;
            });
            const eventYMax = Math.max(...eventValueArray);

            this.chartData = [{
                data: model.breathPeriodChartDataArray,
                title: '睡眠分期',
                yMin: 0,
                yMax: 5,
                tooltipTitle: "",
                tooltipUnit: "",
                interval: 1,
                formatter: (value) => {
                    return value === void 0 ? '' : SleepChartTool.sleepPeriodStatusToStr(value.toString());
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : SleepChartTool.sleepPeriodStatusToStr(value.toString());
                },
                rich: {},
                areaStyle: null,
                type: 'line'
            },
            {
                data: pulseChartDataArray,
                title: '脉率',
                yMin: 0,
                yMax: yMax > 120 ? yMax : 120,
                tooltipTitle: "脉率",
                tooltipUnit: "bpm",
                interval: 1000,
                formatter: (value, index) => {
                    if (value === 0) {
                        return '{a|' + `${value}bpm` + '}';
                    } else if (value >= 120) {
                        return '{b|' + `${value}bpm` + '}';
                    }
                    return `${value}bpm`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}bpm`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: null,
                type: 'line'
            },
            {
                data: model.bloodOxygenChartDataArray,
                title: '血氧',
                yMin: 40,
                yMax: 100,
                tooltipTitle: "血氧",
                tooltipUnit: "%",
                interval: 1000,
                formatter: (value) => {
                    if (value === 40) {
                        return '{a|' + `${value}%` + '}';
                    } else if (value === 100) {
                        return '{b|' + `${value}%` + '}';
                    }
                    return value === void 0 ? '' : `${value}%`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}%`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: null,
                type: 'line'
            },
            {
                data: model.breathChartDataArray,
                title: '呼吸',
                yMin: 0,
                yMax: 40,
                tooltipTitle: "呼吸",
                tooltipUnit: "bpm",
                interval: 1000,
                formatter: (value) => {
                    if (value === 0) {
                        return '{a|' + `${value}bmp` + '}';
                    } else if (value === 40) {
                        return '{b|' + `${value}bmp` + '}';
                    }
                    return value === void 0 ? '' : `${value}bmp`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}bpm`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: null,
                type: 'line'
            },
            {
                data: breathEventChartDataArray,
                title: '呼吸事件',
                yMin: 0,
                yMax: eventYMax > 60 ? eventYMax : 60,
                tooltipTitle: "持续时间",
                tooltipUnit: "秒",
                interval: 1000,
                formatter: (value) => {
                    if (value === 0) {
                        return '{a|' + `${value}s` + '}';
                    } else if (value >= 60) {
                        return '{b|' + `${value}s` + '}';
                    }
                    return `${value}s`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}s`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: null,
                type: 'bar'
            },
            {
                data: model.moveRateChartDataArray,
                title: '体动占比',
                yMin: 0,
                yMax: 60,
                tooltipTitle: "时长",
                tooltipUnit: "秒",
                interval: 1000,
                formatter: (value) => {
                    if (value === 0) {
                        return '{a|' + `${value}s` + '}';
                    } else if (value >= 60) {
                        return '{b|' + `${value}s` + '}';
                    }
                    return `${value}s`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}s`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: {},
                type: 'line'
            },
            {
                data: model.moveRangeChartDataArray,
                title: '体动幅度',
                yMin: 0,
                yMax: 0.001,
                tooltipTitle: "",
                tooltipUnit: "",
                interval: 1000,
                formatter: (value) => {
                    if (value === 0) {
                        return '{a|' + `${value}` + '}';
                    } else if (value === 0.001) {
                        return '{b|' + `${value}` + '}';
                    }
                    return `${value}`
                },
                valueFormatter:(value) => {
                    return value === void 0 ? '' : `${value}`
                },
                rich: {
                    a: {
                        lineHeight: 30,
                        verticalAlign: 'top'
                    },
                    b: {
                        lineHeight: 30,
                        verticalAlign: 'bottom'
                    },
                },
                areaStyle: {},
                type: 'line'
            },
            ]
            this.chartData = this.chartData.filter((item, index) => {
                return this.selectedStatus[index]
            })
            this.drawLine();
        },
        drawLine() {
            const xAxis = [];
            const yAxis = [];
            const grid = [];
            const series = [];
            this.chartData.forEach((item, index, arr) => {
                // 设置 x 坐标数据
                xAxis.push({
                    type: 'time',
                    axisLabel: {
                        formatter: '{HH}:{mm}',
                        lineStyle: {
                            color: "rgba(255,255,255)",
                        },
                        show: index == arr.length - 1, // 最后一个表显示 x 轴参数,
                    },
                    axisTick: {
                        show: index == arr.length - 1, // 最后一个表显示 x 轴参数,
                    },
                    gridIndex: index,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "#6CC291",
                        }
                    },
                });

                // 设置 series 数据
                series.push({
                    type: item.type,
                    showSymbol: false,
                    connectNulls: true,
                    lineStyle: {
                        width: 1,
                    },
                    name: item.title,
                    data: item.data,
                    xAxisIndex: index,
                    yAxisIndex: index,
                    areaStyle: item.areaStyle
                });

                // 设置 y 坐标数据
                yAxis.push({
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(0,0,0,1)",
                        },
                    },
                    interval: item.interval,
                    // splitNumber: 1,
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: true, // 最后一个表显示 x 轴参数,
                    },
                    gridIndex: index,
                    type: 'value',
                    min: item.yMin,
                    max: item.yMax,
                    axisLabel: {
                        formatter: item.formatter,
                        rich: item.rich
                    },
                });
                // 设置 grid 数据
                const viewBox = this.boxHeight - this.dataZoomHeight - this.offsetTop;
                // 计算间隔相差
                const space = this.baseSpace / arr.length;
                // 计算 每个图表的高度
                const chartItemHeight =
                    (viewBox - space * (arr.length - 1)) / arr.length;
                let _top, _bottom;
                _top = this.offsetTop + (space + chartItemHeight) * index;
                _bottom = this.boxHeight - (_top + chartItemHeight);

                grid.push({
                    left: 100,
                    top: _top,
                    bottom: _bottom,
                    right: 18,
                });
            })
            const tooltipFormatter = (params) => {
                // 排序
                params.sort((a, b) => a.axisIndex - b.axisIndex);
                let html = `<div style="color:#fff;padding: 0 10px 10px 10px">时间：${params[0].axisValueLabel}</div>`;
                params.forEach((item, index, arr) => {
                    const valueFormatter = this.chartData[item.seriesIndex].valueFormatter
                    html += `
                <div style="color:#fff;display: flex;flex-flow: row nowrap;align-items: center;padding:10px;border-top:1px solid rgba(255,255,255,.2)">
                  <div style="width:10px;height:10px;background:${item.color};border-radius:10px;margin-right:10px"></div>
                  <div>
                    <p style="color:#fff;">类型：${item.seriesName}</p>
                    <p style="color:#fff;">数值：${valueFormatter(item.value[1])}</p>
                  </div>
                </div>
              `;
                });

                return html;
            }
            this.chart.setOption({
                tooltip: {
                    trigger: "axis",
                    borderWidth: 0,
                    borderColor: "#ccc",
                    padding: 10,
                    backgroundColor: "rgba(66, 65, 65, 1)",
                    textStyle: {
                        color: "#000",
                    },
                    formatter: tooltipFormatter,
                    axisPointer: {
                        // type: "cross",
                        lineStyle: {
                            color: "red",
                            type: "solid",
                        },
                        // label: {
                        //     show: true,
                        //     backgroundColor: "transparent",
                        //     formatter: (params) => {
                        //         if (params.axisIndex == this.chartData.length - 1) {
                        //             return params.value;
                        //         }
                        //     },
                        // },
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                color: 'red',
                grid: grid,
                xAxis: xAxis,
                yAxis: yAxis,
                series: series
            }, true)
        },
    },
}
</script>
<style scoped lang='scss'>
.flexCenter {
    display: flex;
    align-items: center;
}

#contentBox {
    width: 100%;
    height: 800px;
    box-sizing: border-box;
    // @extend .flexCenter;
    // align-items: flex-start;
    position: relative;
    // border: 2px solid #000000;

    .box {
        position: absolute;
        border: 2px solid #000000;
        width: calc(100% - 44px);
        top: 15px;
        left: 20px;
        background: #F5F7DD;
        // z-index: 10;
    }


    .leftTitleBox {
        position: absolute;
        left: 22px;
        top: 17px;
        width: 120px;
        @extend .flexCenter;
        flex-flow: column nowrap;
        justify-content: space-between;
        background-color: #F0EFEF;

        .itemTitle {
            width: 100%;
            @extend .flexCenter;
            justify-content: center;
            font-size: 18px;
            color: #000000;
            line-height: 1.5em;
            border-bottom: 1px solid #707070;

            .itemTitleContent {
                width: 50px;
            }
        }
    }

    #sheng-fu-bao-chart {
        position: absolute;
        left: 41px;
        top: 17px;
        width: calc(100% - 45px);
        height: 800px;
    }
}
</style>