var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({ height: _vm.boxHeight - _vm.dataZoomHeight - _vm.offsetTop + 50 + 'px' }),attrs:{"id":"contentBox"}},[_c('div',{staticClass:"box",style:({ height: _vm.boxHeight - _vm.dataZoomHeight - _vm.offsetTop + 'px' })}),_c('div',{staticClass:"leftTitleBox",style:({
        height: _vm.boxHeight - _vm.dataZoomHeight - _vm.offsetTop + 'px',
        paddingTop: _vm.offsetTop + 'px',
    })},_vm._l((_vm.chartData),function(item,k){return _c('div',{key:k,staticClass:"itemTitle",style:({
            height: `calc((100% - ${(_vm.baseSpace / _vm.chartData.length) *
                2 *
                (_vm.chartData.length - 1)
                }px) / ${_vm.chartData.length})`,
        })},[_c('div',{staticClass:"itemTitleContent"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0),_c('div',{ref:"chartBox",attrs:{"id":_vm.chartId}})])
}
var staticRenderFns = []

export { render, staticRenderFns }