<template>
    <el-dialog :visible.sync="dialogTableVisible" title="选择报告" width="700px">
        <el-table :data="tableDataList" class="table" height="430px" v-el-table-infinite-scroll="loadMore" :infinite-scroll-disabled="disabled">
            <el-table-column property="name" label="姓名" width="150" align="center" />
            <el-table-column property="sn" label="设备SN" width="150" align="center" />
            <el-table-column property="createTime" label="报告生成时间" width="150" align="center" />
            <el-table-column align="center">
                <template slot="header" slot-scope="scope">
                    <el-input v-model="keywords" size="mini" placeholder="请输入姓名或设备SN" />
                </template>
                <template slot-scope="scope">
                    <button class="confirm" @click="onClickConfirm(scope.$index, scope.row)">选择</button>
                </template>
            </el-table-column>
        </el-table>
    </el-dialog>
</template>

<script>
import { Dialog, Table, TableColumn, Button } from 'element-ui';
import { DateTool } from "@js/date-tool.js";
import { ReportModel } from "@/views/Report/report-model-sfb.js";
import ElTableInfiniteScroll from 'el-table-infinite-scroll';

export default {
    components: {
        ElDialog: Dialog,
        ElTable: Table,
        ElTableColumn: TableColumn,
        ElButton: Button
    },
    directives: {
        'el-table-infinite-scroll': ElTableInfiniteScroll
    },
    props: {
        hospitalId: Number
    },
    data() {
        return {
            keywords: '',
            currentPage: 1,
            pageSize: 20,
            dialogTableVisible: false,
            reportList: [1,2,3],
            disabled: false
        };
    },
    watch: {
        keywords: {
            handler(newVal) {
                console.log(newVal)
                this.reportListRequest(true)
            }
        }
    },

    computed: {
        tableDataList() {
            return this.reportList.map(el => {
                return {
                    name: placeholderText(el.trueName),
                    sn: el.sn,
                    createTime: placeholderText(DateTool.millisecondTimestampToStr(el.recordDay, "Y-M-D h:m")),
                }
            })
        }
    },

    mounted() {
    },
    methods: {
        show() {
            this.reportList.splice(0, this.reportList.length)
            this.keywords = ''
            this.reportListRequest(true)
            this.dialogTableVisible = true
        },
        async onClickConfirm(index, item) {
            try {
                const params = {
                    "reportNumber": this.reportList[index]?.reportNumber,
                }
                const response = await this.$api.getSleepReportDetail(params)
                const model = new ReportModel(response);
                this.$emit('onSelectReport', model)
                this.dialogTableVisible = false
            } catch (error) {
                this.$toast.showRed(error);
            }

        },

        async reportListRequest(refresh) {
            if (refresh) {
                this.disabled = false
            }
            try {
                const params = {
                    "keyWords": this.keywords || null,
                    "pageNum": refresh ? 1 : this.currentPage + 1,
                    "hospitalId": this.hospitalId,
                    "pageSize": this.pageSize,
                }
                const response = await this.$api.getSleepReportPageList(params)
                if (refresh) {
                    this.$set(this, 'reportList', response.list) 
                    this.currentPage = 1
                } else {
                    this.reportList = this.reportList.concat(response.list)
                    this.currentPage += 1
                }
                if (this.currentPage === response.pages) {
                    this.disabled = true
                }
            } catch (error) {
                this.$toast.showRed(error);
            }
        },

        loadMore() {
            if (this.disabled) return;
            this.reportListRequest()
        }
    },

}
</script>
<style scoped lang='scss'>
@mixin button-style {
    color: #325FAC;
    font-size: 16px;
    font-family: inherit;
    padding: 0;
}

.table {

    /deep/.el-table__row td .cell {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // /deep/.el-table__row {
    //     // background-color: red;
    //     border-bottom: 10px solid #eee !important;
    // }
    &::before {
        height: 0;
    }
}

.confirm {
    @include button-style;
}
</style>